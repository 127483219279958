<template>
  <div>
    <v-card flat dense>
      <v-form ref="form" lazy-validation>
        <v-flex xs12>
          <v-layout row wrap align-center>
            <v-flex xs12 md4 v-if="!is_savings">
              <v-text-field
                v-model="or_date"
                type="date"
                label="OR Date"
                class="mx-2"
                required
                :rules="rules.default_max_25_character_and_no_empty_rule"
                @keyup="overdue_calculator"
                @change="overdue_calculator"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 md4 v-if="!is_savings">
              <v-text-field
                v-model="or_no"
                label="OR No."
                class="mx-2"
                required
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4 v-if="payment_for === 'Raw Lots Payment'">
              <v-select
                class="mx-3 pt-5"
                v-model="raw_lots_data"
                :items="raw_lots_details"
                item-value="raw_lots_data"
                item-text="raw_lots_data"
                label="Lot Details"
                required
                @change="
                  selected_block_lot(
                    raw_lots_details
                      .map(function (x) {
                        return x.raw_lots_data
                      })
                      .indexOf(raw_lots_data),
                  )
                "
                :rules="rules.combobox_rule"
              ></v-select>
            </v-flex>
            <v-flex xs12 md4 v-if="!input_amount && !is_customize_amount">
              <v-select
                class="mx-3 pt-5"
                v-model="amount"
                :items="amount_due"
                item-value="due"
                item-text="due_number"
                label="Amount"
                required
                @change="
                  commission_computation(
                    amount_due
                      .map(function (x) {
                        return x.due
                      })
                      .indexOf(amount),
                  )
                "
              ></v-select>
            </v-flex>
            <v-flex xs12 md4
                    v-if="payment_for === 'Raw Lots Payment'||payment_for === 'Corporate Account Fee'||payment_for === 'Coop Protek Account Fee'
||payment_for === 'Membership Fee'||payment_for === 'Corporate Account Fee'">
              <v-text-field v-model="total_commission" label="Commission" class="mx-2"
                            readonly></v-text-field>
            </v-flex>
            <v-flex xs12 md4 v-if="payment_for === 'Raw Lots Payment'">
              <v-text-field v-model="net_amount" label="Net" class="mx-2" readonly></v-text-field>
            </v-flex>
            <v-flex xs12 md4 v-if="input_amount || is_customize_amount">
              <v-text-field
                v-model="amount"
                :min="0"
                type="number"
                label="Amount"
                class="mx-2"
                required
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4
                    v-if="this.payment_for === 'Gadget Payment' || this.payment_for === 'Loan Payment'">
              <v-text-field v-model="loans_due_date" label="Payment For" class="mx-2"
                            readonly></v-text-field>
            </v-flex>
            <v-flex xs12 md4
                    v-if="this.payment_for === 'Gadget Payment' || this.payment_for === 'Loan Payment' && (input_amount || is_customize_amount)">
              <v-text-field v-model="over_due_charge" label="Overdue Charge" class="mx-2"
                            :min="0"
                            type="number"
                            required
                            :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4
                    v-if="this.payment_for === 'Gadget Payment' || this.payment_for === 'Loan Payment' && !(input_amount || is_customize_amount)">
              <v-text-field v-model="over_due_charge" label="Overdue Charge" class="mx-2"
                            readonly></v-text-field>
            </v-flex>
            <v-checkbox
              v-if="payment_for === 'Loan Payment' && position==='ADMIN'"
              v-model="is_customize_amount"
              label="is Customize Amount"
              hide-details
              class="me-3 mt-1"
            >
            </v-checkbox>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert color="warning" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ alert_message }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn color="primary" class="me-3 mt-4" @click="save_payments" v-if="!saving"> Save
                changes
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
            </v-col>
          </v-layout>
        </v-flex>
      </v-form>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    props: {
      collector_id: Number,
      payment_for: String,
      input_amount: Boolean,

      selected_search_items: Object,

      deposit_slip_id: Number,
      balance: Number,
      or_series_available: String,
      or_series_available_id: Number,
    },
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    data() {
      return {
        is_customize_amount: false,
        encoded_id: 0,

        over_due_charge: 0,
        saving: false,
        alert: false,
        alert_message: '',

        is_savings: false,
        savings_id: '',

        or_date: '',
        or_no: '',

        loans_date_accomplished: '',
        loans_due_date: '',
        mode_of_loan: '',

        raw_lots_data: '',
        raw_lots_details: [],

        amount: '',
        amount_due: [],

        total_commission: 0,
        commission: 0,
        net_amount: 0,

        lots_rent_id: 0,
        installment_raw_lots: 0,
        total_payment_raw: 0,
        total_payable_raw: 0,
        have_commission: false,
        commision_per_month: 0,
      }
    },
    watch: {
      selected_search_items: function () {
        this.selected_payment_for()
      },
      amount: function () {
        this.overdue_calculator()
      },
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['branch_id', 'month_of', 'position']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('members_payment', ['register_new_members_payment', 'corporate_payment']),
      ...mapActions('loans_payment', ['register_new_loan_payment']),
      ...mapActions('processing_payment', ['register_new_processing_payment']),
      ...mapActions('share_capital', ['register_new_share_capital']),
      ...mapActions('time_deposits', ['register_new_time_with_deposit']),
      ...mapActions('loans_insurance_payment', ['register_new_loan_insurance_payment']),
      ...mapActions('mortuary_insurance_payment', ['register_new_mortuary_insurance_payment']),
      ...mapActions('foundation_payment', ['register_new_foundation_payment']),
      ...mapActions('gadget_loans_payment', ['register_new_gadget_loan_payment']),
      ...mapActions('gadget_processing_payment', ['register_new_gadget_processing_payment']),
      ...mapActions('raw_lots_payment', ['register_new_raw_lots_payment']),
      ...mapActions('regular_savings_data', ['register_savings_deposit_in_bank']),
      ...mapActions('transmital_passbooks', ['register_passbook_deposit_in_bank']),
      ...mapActions('coop_protek_information', ['coop_protek_membership_payment']),
      ...mapActions('coop_protek_savings_data', ['deposit_coop_protek']),
      overdue_calculator() {
        // if (this.payment_for === 'Gadget Payment' || this.payment_for === 'Loan Payment') {
        //   var start_date_over_due = moment('2022-11-01', 'YYYY-MM-DD')
        //   var date_accomplished = moment(this.loans_date_accomplished, 'MMMM D, YYYY')
        //   var due_date = moment(this.loans_due_date, 'MMMM D, YYYY')
        //   var ordate = moment(this.or_date, 'YYYY-MM-DD')
        //   var due_amount = this.selected_search_items.loans.amount_due
        //   var type = this.selected_search_items.loans.type_of_loan
        //   this.over_due_charge = 0
        //   if (type != 'Employee' && type != 'Salary (GOODLIFE)' && type != 'Incentives') {
        //     if (date_accomplished.isAfter(start_date_over_due)) {
        //       var isafter = ordate.isAfter(due_date, 'month')
        //       if (isafter) {
        //         var monthsToPay = (this.amount / due_amount)
        //         var backlogs = ordate.startOf('month').diff(due_date.startOf('month'), 'months', true)
        //         if (monthsToPay > backlogs) {
        //           monthsToPay = backlogs
        //         }
        //         this.over_due_charge = (parseFloat(due_amount) * monthsToPay) * 0.05
        //       }
        //     }
        //   }
        // }
      },
      selected_payment_for() {
        this.encoded_id = 0
        this.amount_due = []
        switch (this.payment_for) {
          case 'Membership Fee':
            this.amount_due.push({
              due_number: 500,
              due: '500.00',
              commission: 50,
            })
            break
          case 'Corporate Account Fee':
            this.amount_due.push({
              due_number: 1000,
              due: '1000.00',
              commission: 100,
            })
            break
          case 'Coop Protek Account Fee':
            this.amount_due.push({
              due_number: 100,
              due: '100.00',
              commission: 50,
            })
            break
          case 'Coop Protek Savings':
            if (this.selected_search_items != null) {
              this.amount_due = this.selected_search_items.due_amounts
            }
            break
          case 'Loan Payment':
          case 'Gadget Payment':
            if (this.selected_search_items != null) {
              this.amount_due = this.selected_search_items.due_amounts
              // this.due_amount = this.selected_search_items.due_amount
              this.loans_date_accomplished = this.selected_search_items.loans.date_accomplished
              this.loans_due_date = this.selected_search_items.loans.due_date
              this.mode_of_loan = this.selected_search_items.loans.mode_of_loan
            }
            break
          case 'Loan Insurance':
          case 'Gadget Insurance':
          case 'Loan Processing Fee':
          case 'Gadget Processing Fee':
          case 'Mortuary Insurance':
          case 'Foundation':
            if (this.selected_search_items != null) {
              this.amount_due = this.selected_search_items.due_amounts
            }
            break
          case 'Raw Lots Payment':
            if (this.selected_search_items != null) {
              this.raw_lots_details = this.selected_search_items.raw_lots_rent
            }
            break
          case 'Regular Savings':
          case 'Passbook Deposit':
          case 'Share Capital':
          case 'Time Deposit':
            this.is_savings = true
            if (this.selected_search_items != null) {
              this.amount_due = this.selected_search_items.due_amounts
              this.savings_id = this.selected_search_items.id
            }
            break
          default:
            break
        }
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_block_lot(index) {
        if (index != -1) {
          var total_months = 72
          if (this.raw_lots_details[index].location_data.years === 8) {
            total_months = 100
          }
          for (var x = 1; x <= total_months; x++) {
            this.amount_due.push({
              due: this.raw_lots_details[index].location_data.amount * x,
              due_number: this.formatPrice(this.raw_lots_details[index].location_data.amount * x),
            })
          }

          this.lots_rent_id = this.raw_lots_details[index].id
          this.installment_raw_lots = this.raw_lots_details[index].count
          this.total_payment_raw = this.raw_lots_details[index].total_payment
          this.total_payable_raw = this.raw_lots_details[index].location_data.amount * total_months
          if (this.raw_lots_details[index].count < 24) {
            this.have_commission = true
            this.commision_per_month =
              ((this.raw_lots_details[index].location_data.amount * total_months) / 24) *
              (this.raw_lots_details[index].lot_agent.lot_agent_percentage[0].percentage / 100)
          }
        }
      },
      commission_computation(index) {
        this.encoded_id = this.amount_due[index].id
        this.commission = this.amount_due[index].commission
        this.over_due_charge = this.amount_due[index].penalty
        this.loans_due_date = this.amount_due[index].due_date
        if (this.payment_for === 'Raw Lots Payment') {
          if (this.installment_raw_lots < 24) {
            var payment = index + 1
            if (this.installment_raw_lots + payment <= 24) {
              this.total_commission = Math.round(this.commision_per_month) * (index + 1)
            } else {
              this.total_commission = Math.round(this.commision_per_month) * (24 - this.installment_raw_lots)
            }
          }
          this.net_amount = parseFloat(this.amount) - parseFloat(this.total_commission)
        } else if (this.payment_for === 'Corporate Account Fee' || this.payment_for === 'Coop Protek Account Fee'
          || this.payment_for === 'Membership Fee' || this.payment_for === 'Corporate Account Fee') {
          this.total_commission = this.amount_due[index].commission
        }
      },
      save_payments() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var proceed = true
          var total_amount_to = this.amount
          if (this.payment_for == 'Raw Lots Payment') {
            total_amount_to = this.net_amount
          }
          if (this.balance <= 0 || this.balance - total_amount_to < -1000) {
            proceed = false
            this.alert = true
            this.alert_message = 'Please Load Deposit Slip First'
            this.saving = false
          } else {
            if (
              this.payment_for != 'Regular Savings' &&
              this.payment_for != 'Passbook Deposit' &&
              this.payment_for != 'Share Capital' &&
              this.payment_for != 'Time Deposit'
            ) {
              if (this.or_series_available == 0) {
                proceed = false
                this.alert = true
                this.alert_message = 'Please Load Or First'
                this.saving = false
              } else {
                var currentOr = this.or_series_available.split('-')
                if (currentOr.length > 0) {
                  if (currentOr[0] != this.or_no) {
                    proceed = false
                    this.alert = true
                    this.alert_message = 'Please Check Or No.'
                    this.saving = false
                  }
                } else {
                  proceed = false
                  this.alert = true
                  this.alert_message = "OR can't split"
                  this.saving = false
                }
              }
            }
          }
          //if no error above then proceed
          if (proceed) {
            switch (this.payment_for) {
              case 'Membership Fee': {
                if (this.selected_search_items == null) {
                  this.alert = true
                  this.alert_message = 'Please select Member First'
                  this.saving = false
                } else {
                  this.register_new_members_payment({
                    branch_id: this.branch_id,
                    member_id: this.selected_search_items.id,
                    deposit_slip_id: this.deposit_slip_id,
                    or_date: moment(this.or_date, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                    or_no: this.or_series_available,
                    amount: this.amount,
                    commission: this.commission,
                    month_of: this.month_of,
                    balance: this.balance - this.amount,
                    series_or_id: this.or_series_available_id,
                    collector_id: this.collector_id,
                  })
                    .then(() => {
                      this.change_snackbar({
                        show: true,
                        color: 'success',
                        text: this.payment_for + ' SUCCESSFULLY !',
                      })
                      this.$emit('collector', this.collector_id)
                      this.saving = false
                    })
                    .catch(error => {
                      this.alert = true
                      this.alert_message = error
                      this.saving = false
                    })
                }
                break
              }
              case 'Corporate Account Fee': {
                if (this.selected_search_items == null) {
                  this.alert = true
                  this.alert_message = 'Please select Member First'
                  this.saving = false
                } else {
                  this.corporate_payment({
                    branch_id: this.branch_id,
                    member_id: this.selected_search_items.id,
                    deposit_slip_id: this.deposit_slip_id,
                    or_date: moment(this.or_date, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                    or_no: this.or_series_available,
                    amount: this.amount,
                    commission: this.commission,
                    month_of: this.month_of,
                    balance: this.balance - this.amount,
                    series_or_id: this.or_series_available_id,
                    collector_id: this.collector_id,
                  })
                    .then(() => {
                      this.change_snackbar({
                        show: true,
                        color: 'success',
                        text: this.payment_for + ' SUCCESSFULLY !',
                      })
                      this.$emit('collector', this.collector_id)
                      this.saving = false
                    })
                    .catch(error => {
                      this.alert = true
                      this.alert_message = error
                      this.saving = false
                    })
                }
                break
              }
              case 'Coop Protek Account Fee': {
                if (this.selected_search_items == null) {
                  this.alert = true
                  this.alert_message = 'Please select Member First'
                  this.saving = false
                } else {
                  this.coop_protek_membership_payment({
                    branch_id: this.branch_id,
                    member_id: this.selected_search_items.id,
                    deposit_slip_id: this.deposit_slip_id,
                    or_date: moment(this.or_date, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                    or_no: this.or_series_available,
                    amount: this.amount,
                    commission: this.commission,
                    month_of: this.month_of,
                    balance: this.balance - this.amount,
                    series_or_id: this.or_series_available_id,
                    collector_id: this.collector_id,
                  })
                    .then(() => {
                      this.change_snackbar({
                        show: true,
                        color: 'success',
                        text: this.payment_for + ' SUCCESSFULLY !',
                      })
                      this.$emit('collector', this.collector_id)
                      this.saving = false
                    })
                    .catch(error => {
                      this.alert = true
                      this.alert_message = error
                      this.saving = false
                    })
                }
                break
              }
              case 'Coop Protek Savings': {
                if (this.selected_search_items == null) {
                  this.alert = true
                  this.alert_message = 'Please select Member First'
                  this.saving = false
                } else {
                  this.deposit_coop_protek({
                    branch_id: this.branch_id,
                    coop_protek_info_id: this.selected_search_items.id,
                    deposit_slip_id: this.deposit_slip_id,
                    or_date: this.or_date,
                    or_no: this.or_series_available,
                    counter_month: (this.amount_due
                      .map(function (x) {
                        return x.due
                      })
                      .indexOf(this.amount) + 1),
                    deposit: this.amount,
                    month_of: this.month_of,
                    balance: this.balance - this.amount,
                    series_or_id: this.or_series_available_id,
                    collector_id: this.collector_id,
                  })
                    .then(() => {
                      this.change_snackbar({
                        show: true,
                        color: 'success',
                        text: this.payment_for + ' SUCCESSFULLY !',
                      })
                      this.$emit('collector', this.collector_id)
                      this.saving = false
                    })
                    .catch(error => {
                      this.alert = true
                      this.alert_message = error
                      this.saving = false
                    })
                }
                break
              }
              case 'Loan Payment': {
                // var due_amount = this.selected_search_items.loans.amount_due
                // var indexOfAmount = parseInt(this.amount / due_amount)
                // var due_date = moment(this.loans_due_date, 'MMMM D, YYYY').format('MMMM D, YYYY')
                //
                // if (parseInt(indexOfAmount) > 0) {
                //   switch (this.mode_of_loan) {
                //     case 'Weekly':
                //       due_date = moment(this.loans_due_date, 'MMMM D, YYYY')
                //         .add(7 * indexOfAmount, 'day')
                //         .format('MMMM D, YYYY')
                //       break
                //     case '15/30':
                //       due_date = moment(this.loans_due_date, 'MMMM D, YYYY')
                //         .add(15 * indexOfAmount, 'day')
                //         .format('MMMM D, YYYY')
                //       break
                //     case 'Monthly':
                //       due_date = moment(this.loans_due_date, 'MMMM D, YYYY')
                //         .add(1 * indexOfAmount, 'months')
                //         .format('MMMM D, YYYY')
                //       break
                //     default:
                //       break
                //   }
                // }
                this.register_new_loan_payment({
                  id: this.encoded_id,
                  branch_id: this.branch_id,
                  member_id: this.selected_search_items.member_id,
                  loans_data_id: this.selected_search_items.id,
                  deposit_slip_id: this.deposit_slip_id,
                  or_date: moment(this.or_date, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                  or_no: this.or_series_available,
                  amount: this.amount,
                  month_of: this.month_of,
                  // balance: this.balance - parseFloat(this.amount ),
                  balance: parseFloat(this.balance) - (parseFloat(this.amount) + parseFloat(this.over_due_charge)),
                  series_or_id: this.or_series_available_id,
                  collector_id: this.collector_id,
                  over_due_charge: this.over_due_charge,

                  due_date: this.loans_due_date,
                })
                  .then(() => {
                    this.change_snackbar({
                      show: true,
                      color: 'success',
                      text: this.payment_for + ' SUCCESSFULLY !',
                    })
                    this.$emit('collector', this.collector_id)
                    this.saving = false
                  })
                  .catch(error => {
                    this.alert = true
                    this.alert_message = error
                    this.saving = false
                  })
                break
              }
              case 'Gadget Payment': {
                var due_amount = this.selected_search_items.loans.amount_due
                var indexOfAmount = parseInt(this.amount / due_amount)
                var due_date = moment(this.loans_due_date, 'MMMM D, YYYY').format('MMMM D, YYYY')
                if (parseInt(indexOfAmount) > 0) {
                  switch (this.mode_of_loan) {
                    case 'Weekly':
                      due_date = moment(this.loans_due_date, 'MMMM D, YYYY')
                        .add(7 * indexOfAmount, 'day')
                        .format('MMMM D, YYYY')
                      break
                    case '15/30':
                      due_date = moment(this.loans_due_date, 'MMMM D, YYYY')
                        .add(15 * indexOfAmount, 'day')
                        .format('MMMM D, YYYY')
                      break
                    case 'Monthly':
                      due_date = moment(this.loans_due_date, 'MMMM D, YYYY')
                        .add(1 * indexOfAmount, 'months')
                        .format('MMMM D, YYYY')
                      break
                    default:
                      break
                  }
                }
                this.register_new_gadget_loan_payment({
                  branch_id: this.branch_id,
                  member_id: this.selected_search_items.member_id,
                  loans_data_id: this.selected_search_items.id,
                  deposit_slip_id: this.deposit_slip_id,
                  or_date: moment(this.or_date, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                  or_no: this.or_series_available,
                  amount: this.amount,
                  month_of: this.month_of,
                  balance: this.balance - parseFloat(this.amount + this.over_due_charge),
                  // balance: this.balance - parseFloat(this.amount),
                  series_or_id: this.or_series_available_id,
                  collector_id: this.collector_id,
                  over_due_charge: this.over_due_charge,

                  due_date: due_date,
                })
                  .then(() => {
                    this.change_snackbar({
                      show: true,
                      color: 'success',
                      text: this.payment_for + ' SUCCESSFULLY !',
                    })
                    this.$emit('collector', this.collector_id)
                    this.saving = false
                  })
                  .catch(error => {
                    this.alert = true
                    this.alert_message = error
                    this.saving = false
                  })
                break
              }
              case 'Loan Insurance': {
                if (this.encoded_id === 0) {
                  this.change_snackbar({
                    show: true,
                    color: 'error',
                    text: 'PLEASE SELECT AMOUNT',
                  })
                  this.$emit('collector', this.collector_id)
                  this.saving = false
                } else {
                  var index = this.amount_due
                    .map(function (x) {
                      return x.due
                    })
                    .indexOf(this.amount)
                  var commission = this.amount_due[index].commission
                  this.register_new_loan_insurance_payment({
                    id: this.encoded_id,
                    branch_id: this.branch_id,
                    member_id: this.selected_search_items.member_id,
                    loans_data_id: this.selected_search_items.id,
                    deposit_slip_id: this.deposit_slip_id,
                    or_date: moment(this.or_date, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                    or_no: this.or_series_available,
                    amount: this.amount,
                    commission: commission,
                    addtional_income: 0,
                    month_of: this.month_of,
                    balance: this.balance - this.amount,
                    series_or_id: this.or_series_available_id,
                    collector_id: this.collector_id,
                  })
                    .then(() => {
                      this.change_snackbar({
                        show: true,
                        color: 'success',
                        text: this.payment_for + ' SUCCESSFULLY !',
                      })
                      this.$emit('collector', this.collector_id)
                      this.saving = false
                      this.amount = 0
                    })
                    .catch(error => {
                      this.alert = true
                      this.alert_message = error
                      this.saving = false
                    })
                }
                break
              }
              case 'Loan Processing Fee': {
                if (this.encoded_id === 0) {
                  this.change_snackbar({
                    show: true,
                    color: 'error',
                    text: 'PLEASE SELECT AMOUNT',
                  })
                  this.$emit('collector', this.collector_id)
                  this.saving = false
                } else {
                  this.register_new_processing_payment({
                    id: this.encoded_id,
                    branch_id: this.branch_id,
                    member_id: this.selected_search_items.member_id,
                    loans_data_id: this.selected_search_items.id,
                    deposit_slip_id: this.deposit_slip_id,
                    or_date: moment(this.or_date, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                    or_no: this.or_series_available,
                    amount: this.amount,
                    month_of: this.month_of,
                    balance: this.balance - this.amount,
                    series_or_id: this.or_series_available_id,
                    collector_id: this.collector_id,
                  })
                    .then(() => {
                      this.change_snackbar({
                        show: true,
                        color: 'success',
                        text: this.payment_for + ' SUCCESSFULLY !',
                      })
                      this.$emit('collector', this.collector_id)
                      this.saving = false
                      this.amount = 0
                    })
                    .catch(error => {
                      this.alert = true
                      this.alert_message = error
                      this.saving = false
                    })
                }
                break
              }
              case 'Gadget Processing Fee': {
                this.register_new_gadget_processing_payment({
                  branch_id: this.branch_id,
                  member_id: this.selected_search_items.member_id,
                  loans_data_id: this.selected_search_items.id,
                  deposit_slip_id: this.deposit_slip_id,
                  or_date: moment(this.or_date, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                  or_no: this.or_series_available,
                  amount: this.amount,
                  month_of: this.month_of,
                  balance: this.balance - this.amount,
                  series_or_id: this.or_series_available_id,
                  collector_id: this.collector_id,
                })
                  .then(() => {
                    this.change_snackbar({
                      show: true,
                      color: 'success',
                      text: this.payment_for + ' SUCCESSFULLY !',
                    })
                    this.$emit('collector', this.collector_id)
                    this.saving = false
                  })
                  .catch(error => {
                    this.alert = true
                    this.alert_message = error
                    this.saving = false
                  })
                break
              }
              case 'Share Capital':
                this.register_new_share_capital({
                  savings_id: this.savings_id,
                  deposit_slip_id: this.deposit_slip_id,
                  balance: this.balance - this.amount,
                })
                  .then(() => {
                    this.change_snackbar({
                      show: true,
                      color: 'success',
                      text: this.payment_for + ' SUCCESSFULLY !',
                    })
                    this.$emit('collector', this.collector_id)
                    this.saving = false
                    this.amount = 0
                  })
                  .catch(error => {
                    this.alert = true
                    this.alert_message = error
                    this.saving = false
                  })
                break
              case 'Time Deposit':
                this.register_new_time_with_deposit({
                  savings_id: this.savings_id,
                  deposit_slip_id: this.deposit_slip_id,
                  balance: this.balance - this.amount,
                })
                  .then(() => {
                    this.change_snackbar({
                      show: true,
                      color: 'success',
                      text: this.payment_for + ' SUCCESSFULLY !',
                    })
                    this.$emit('collector', this.collector_id)
                    this.saving = false
                    this.amount = 0
                  })
                  .catch(error => {
                    this.alert = true
                    this.alert_message = error
                    this.saving = false
                  })
                break
              case 'Mortuary Insurance':
                if (this.encoded_id === 0) {
                  this.change_snackbar({
                    show: true,
                    color: 'error',
                    text: 'PLEASE SELECT AMOUNT',
                  })
                  this.$emit('collector', this.collector_id)
                  this.saving = false
                } else {
                  var due_date_mor = moment(this.or_date, 'YYYY-MM-DD').add(1, 'years').format('MMMM D, YYYY')
                  this.register_new_mortuary_insurance_payment({
                    id: this.encoded_id,
                    branch_id: this.branch_id,
                    member_id: this.selected_search_items.id,
                    deposit_slip_id: this.deposit_slip_id,
                    or_date: moment(this.or_date, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                    or_no: this.or_series_available,
                    amount: this.amount,
                    month_of: this.month_of,
                    balance: this.balance - this.amount,
                    series_or_id: this.or_series_available_id,
                    collector_id: this.collector_id,

                    due_date: due_date_mor,
                  })
                    .then(() => {
                      this.change_snackbar({
                        show: true,
                        color: 'success',
                        text: this.payment_for + ' SUCCESSFULLY !',
                      })
                      this.$emit('collector', this.collector_id)
                      this.saving = false
                      this.amount = 0
                    })
                    .catch(error => {
                      this.alert = true
                      this.alert_message = error
                      this.saving = false
                    })
                }
                break
              case 'Foundation':
                if (this.encoded_id === 0) {
                  this.change_snackbar({
                    show: true,
                    color: 'error',
                    text: 'PLEASE SELECT AMOUNT',
                  })
                  this.$emit('collector', this.collector_id)
                  this.saving = false
                } else {
                  var due_date_mor = moment(this.or_date, 'YYYY-MM-DD').add(1, 'years').format('MMMM D, YYYY')
                  this.register_new_foundation_payment({
                    id: this.encoded_id,
                    branch_id: this.branch_id,
                    member_id: this.selected_search_items.id,
                    deposit_slip_id: this.deposit_slip_id,
                    or_date: moment(this.or_date, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                    or_no: this.or_series_available,
                    amount: this.amount,
                    month_of: this.month_of,
                    balance: this.balance - this.amount,
                    series_or_id: this.or_series_available_id,
                    collector_id: this.collector_id,

                    due_date: due_date_mor,
                  })
                    .then(() => {
                      this.change_snackbar({
                        show: true,
                        color: 'success',
                        text: this.payment_for + ' SUCCESSFULLY !',
                      })
                      this.$emit('collector', this.collector_id)
                      this.saving = false
                      this.amount = 0
                    })
                    .catch(error => {
                      this.alert = true
                      this.alert_message = error
                      this.saving = false
                    })
                }

                break
              case 'Raw Lots Payment':
                this.register_new_raw_lots_payment({
                  lots_rent_id: this.lots_rent_id,
                  commission: this.total_commission,
                  net: this.net_amount,
                  balance_raw_lots:
                    parseFloat(this.total_payable_raw) - (parseFloat(this.total_payment_raw) + parseFloat(this.amount)),

                  branch_id: this.branch_id,
                  member_id: this.selected_search_items.id,
                  deposit_slip_id: this.deposit_slip_id,
                  or_date: moment(this.or_date, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                  or_no: this.or_series_available,
                  amount: this.amount,
                  month_of: this.month_of,
                  balance: this.balance - this.amount,
                  series_or_id: this.or_series_available_id,
                  collector_id: this.collector_id,
                })
                  .then(() => {
                    this.change_snackbar({
                      show: true,
                      color: 'success',
                      text: this.payment_for + ' SUCCESSFULLY !',
                    })
                    this.$emit('collector', this.collector_id)
                    this.saving = false
                  })
                  .catch(error => {
                    this.alert = true
                    this.alert_message = error
                    this.saving = false
                  })

                break
              case 'Regular Savings':
                this.register_savings_deposit_in_bank({
                  savings_id: this.savings_id,
                  deposit_slip_id: this.deposit_slip_id,
                  balance: this.balance - this.amount,
                })
                  .then(() => {
                    this.change_snackbar({
                      show: true,
                      color: 'success',
                      text: this.payment_for + ' SUCCESSFULLY !',
                    })
                    this.$emit('collector', this.collector_id)
                    this.saving = false
                  })
                  .catch(error => {
                    this.alert = true
                    this.alert_message = error
                    this.saving = false
                  })

                break
              case 'Passbook Deposit':
                this.register_passbook_deposit_in_bank({
                  savings_id: this.savings_id,
                  deposit_slip_id: this.deposit_slip_id,
                  balance: this.balance - this.amount,
                })
                  .then(() => {
                    this.change_snackbar({
                      show: true,
                      color: 'success',
                      text: this.payment_for + ' SUCCESSFULLY !',
                    })
                    this.$emit('collector', this.collector_id)
                    this.saving = false
                  })
                  .catch(error => {
                    this.alert = true
                    this.alert_message = error
                    this.saving = false
                  })

                break
              default:
                break
            }
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
    },
  }
</script>
